<template>
  <div class="main">
    <div class="post" style="">
      <router-link to="/blog" style="color: #63b4ff; font-weight: bold; font-size: 16px; margin-bottom: 20px">
        <a-button type="primary" size="small" ghost>
          返回
        </a-button>
      </router-link>
      <h1>{{ post.title }}</h1>
      <div style="margin: 15px 0">
        <a-space :size="-12" class="avatar-chips">
          <a-avatar src="/images/img-logo.png" style="background-color: #FFFFFF; margin-left: 0" />
        </a-space>
        <span>Admin</span>
        <span>{{ post.date }}</span>
      </div>
      <div class="content" v-html="post.content"></div>
    </div>
  </div>
</template>

<script>
import marked from 'marked';

export default {
  data() {
    return {
      post: {},
    };
  },
  created() {
    const fileName = this.$route.params.fileName;

    if (fileName) {
      this.loadPost(fileName);
    }
  },

  mounted() {
    document.title = this.post.title || "博客文章";
  },

  methods: {
    async loadPost(fileName) {
      const repo = 'hiEndless/copyapes_blog'; // 替换为你的 GitHub 用户名和仓库名
      const folder = 'content'; // 替换为你在 GitHub 中的文件夹名称
      const apiUrl = `https://raw.githubusercontent.com/${repo}/main/${folder}/${fileName}.md`;
      try {
        const response = await fetch(apiUrl);
        if (!response.ok) throw new Error("Failed to load post");

        const content = await response.text();

        // 解析 YAML 前言和内容
        const [meta, body] = content.split('---').slice(1, 3);
        const attributes = this.parseMeta(meta);

        // 设置文章内容
        const _date = new Date(attributes.date);
        this.post = {
          title: attributes.title,
          date: `${_date.getFullYear()}年${String(_date.getMonth() + 1).padStart(2, '0')}月${String(_date.getDate()).padStart(2, '0')}日`,
          content: this.parseMarkdown(body), // 将 Markdown 转为 HTML
        };

        // 更新页面标题
        document.title = attributes.title;
        document.head.querySelector('meta[name="description"]').setAttribute('content', attributes.description);
        document.head.querySelector('meta[name="Keywords"]').setAttribute('content', attributes.keywords);
        document.head.querySelector('link[rel="canonical"]').setAttribute('href', `https://copyapes.com/blog/${fileName}`);
      } catch (error) {
        console.error("Error loading post:", error);
      }
    },

    parseMeta(meta) {
      const attributes = {};
      meta.split('\n').forEach(line => {
        const [key, value] = line.split(':').map(str => str.trim());
        if (key && value) attributes[key] = value;
      });
      return attributes;
    },

    parseMarkdown(markdown) {
      // 使用 marked.js 等库将 Markdown 转为 HTML
      return marked(markdown);
    }
  },
};
</script>


<style scoped>
.main {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 50px 0;
  @media (max-width: 567px) {
    margin-top: 130px;
  }
}
h1 {
  color: #63b4ff;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  @media (max-width: 567px) {
    font-size: 1.75rem;
    margin-bottom: 0.5rem;
  }
}
.content::v-deep {
  h2 {
    margin: 15px 0;
    font-size: 1.5rem;
  }
  p {
    line-height: 1.8;
    font-size: 16px;
  }
  a {
    color: #63b4ff;
  }
  @media (max-width: 567px) {
    h2 {
      margin: 5px 0;
      font-size: 1.25rem;
    }
    p {
      font-size: 16px;
    }
    a {
      color: #63b4ff;
    }
  }
}

.post {
  background-color: #FFFFFF;
  padding: 40px 60px;
  border-radius: 15px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 70%;
  @media (max-width: 567px) {
    padding: 20px 20px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }
}
span {
  margin-left: 10px;
  font-weight: bold;
}
</style>
